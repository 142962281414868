
import GwError from '~/patterns/molecules/error';
import GwContentTemplate from '~/patterns/templates/content';
import FlexibleSection from '~/patterns/organisms/_flexible-section/flexible-section';

import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';
import GwPageTitle from '~/patterns/molecules/page-title/page-title.vue';

import getPage from '~/graphql/queries/getPage.graphql';
import seo from '~/mixins/seo';

export default {
    components: {
        GwPageContainer,
        GwPageTitle,
        GwError,
        GwContentTemplate,
        'gw-event-template': () => import('~/patterns/templates/event'),
        'gw-page-intro': () => import('~/patterns/molecules/page-intro/page-intro'),
        FlexibleSection,
    },

    mixins: [seo],

    async asyncData(context) { // nuxt2 workaround for SSR layout reactivity with store
        const site = context.$config.appMultisiteHandle,
            uri = context.route.params.pathMatch.replace(/\/$/, ''),
            { data } = await context.$gql.executeQuery(
                getPage,
                { site, uri }
            );

        context.store.commit('ui/setPageType', data?.entry?.typeHandle);
        context.store.commit('ui/setNavigationCTA', data?.entry?.linkField);
        if (data?.entry?.salesBlock?.length) {
            context.store.commit('ui/setContact', data.entry.salesBlock[0]);
        }

        if (data && data.entry) {
            return {
                entry: data.entry,
                notFound: false
            };
        } else {
            context.error({ statusCode: 404, message: 'Page not found' });
            return {
                entry: null,
                notFound: true
            };
        }
    },
};
